import { useTranslation } from 'react-i18next';

const Membership = () => {
  const { t } = useTranslation();
  return (
    <div className="container py-12 mx-auto bg-gray-700 h-[360px]  mt-12 pt-24">
      <div className="flex flex-col items-center justify-center w-full h-full">

        <a
          href="https://forms.gle/4rawgFs7Teph7sb39"
          className="px-12 bg-red hover:bg-deep-green text-gray-200 rounded-lg py-2 font-semibold text-lg"
        >
          {t('facebook.membership')}
        </a>

      </div>
    </div>
  );
};
export default Membership;
