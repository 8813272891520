import { useTranslation } from 'react-i18next';
import { GB, NP } from 'country-flag-icons/react/3x2';
import { useState, memo } from 'react';
import { BsGlobe2 } from 'react-icons/bs';

const Languages = [
  { code: 'en', name: 'English', flag: <GB className="w-8 h-8" /> },
  { code: 'np', name: 'Nepali', flag: <NP className="w-8 h-8" /> },
];

const LanguageSwitcher = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsOpen(false);
  };

  const capitalizedName = i18n.language.slice(0, 2).toUpperCase() + i18n.language.slice(2);

  return (
    <div className="relative">
      <button
        type="button"
        className="flex items-center gap-1 p-1 w-full text-sm border-0 bg-transparent text-gray-200 hover:text-white focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <BsGlobe2 className="text-lg" />
        <span className="text-semibold">{capitalizedName}</span>
      </button>
      {isOpen && (
        <ul className="absolute right-0 mt-2 w-40 bg-gray-800 rounded-md shadow-lg py-1 z-10">
          {Languages.map((lng) => (
            <li key={lng.code} className="flex items-center justify-between p-2 cursor-pointer hover:bg-gray-700">
              <button
                type="button"
                className={`flex items-center gap-2 w-full uppercase text-left text-sm text-gray-200 hover:text-white ${lng.code === i18n.language ? 'font-bold' : ''}`}
                onClick={() => changeLanguage(lng.code)}
              >
                {lng.flag}
                <span className="">{lng.name}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default memo(LanguageSwitcher);
