import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';

const testimonials = [
  {
    id: 1,
    name: 'Savitri Thapa Magar',
    profession: 'Donate- Rs 20,000',
    image: '../assets/savitri.jpg',
    text: 'On her birthday, Savitri Thapa Magar, residing in Hong Kong and originally from Palpa, generously donated Rs. 20,000 to help feed the hungry. We extend our heartfelt wishes for her health, longevity, and continued success. Happy Birthday! Jai Mahankal!',
  },
  {
    id: 2,
    name: 'Vishal Bhushal/Lakshmi Regmi Bhushal',
    profession: 'Donate- Rs 5,105',
    image: '../assets/vislaxmi.jpg',
    text: 'Newlyweds Vishal Bhushal and Lakshmi Regmi Bhushal from Dang set an inspiring example by donating Rs. 5,105 to feed the hungry on their wedding day. We are deeply grateful for their generosity and wish them a happy and prosperous married life. Jai Mahankal!',
  },
  {
    id: 3,
    name: 'Navin Khadka/Mrs. Sushmita Bhattarai',
    profession: 'Donate- Rs 5,000',
    image: '../assets/navin.jpg',
    text: 'On their wedding anniversary, Mr. Navin Khadka and Mrs. Sushmita Bhattarai from Baneshwar donated Rs. 5,000 to feed the hungry. May Lord Pashupatinath bless their compassion and grant them strength. Happy anniversary from all of us. Jai Mahankal!',
  },
  {
    id: 4,
    name: 'Manu Bam Thakuri',
    profession: 'Donate - Rs 5,555',
    image: '../assets/thakuri.jpg',
    text: 'In memory of his late daughter Lakshmi Bam Thakuri, Manu Bam Thakuri from Sitapaila, Kathmandu, donated Rs. 5,555 at Pashupati for Pitru Moksha. We thank for her generosity and wish her family happiness and peace. May Lord Mahankal and Pashupatinath always protect them. Jai Mahankal!',
  },
];

const HelpingHands = () => {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto py-10 text-gray-100">
      <div className="text-center mx-auto mb-10 max-w-xl">
        <div className="inline-block rounded-full  text-base font-medium bg-red py-1 px-4 mb-3">
          {t('helpingHands.helpingHand')}
        </div>
        <h1 className="text-3xl font-bold section-heading">
          {t('helpingHands.trusted')}
        </h1>
      </div>
      <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={30}
        autoplay={{ delay: 4000, disableOnInteraction: true }}
        pagination={{ clickable: true }}
        loop
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide key={testimonial.id}>
            <div className="testimonial-item text-center  rounded-lg p-6 text-gray-200">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="img-fluid bg-light rounded-full p-2 mx-auto mb-4 w-[100px] h-[100px]"
              />
              <div className="testimonial-text rounded text-center">
                <p className="mb-4 min-h-[170px]">
                  {t(`text.${testimonial.text}`)}
                </p>
                <h5 className="font-bold">{t(`helpingHands.${testimonial.name}`)}</h5>
                <span className="text-deep-green text-lg font-bold">{t(`profession.${testimonial.profession}`)}</span>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default HelpingHands;
