/* eslint-disable react/prop-types */
import { IoClose } from 'react-icons/io5';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { features } from '../../util/constant';
import getStyles from '../../util/style';

const Sidebar = ({ mobileOpen, setMobileOpen, lng }) => {
  const sideBarStyles = getStyles(lng);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLinkClick = (href) => {
    setMobileOpen(false);
    navigate(href);
  };
  return (
    <div className={`lg:hidden rounded-r-lg bg-gray-700 font-lato font-semibold text-base text-gray-100 nav ${mobileOpen ? 'nav-visible' : ''}`}>
      <p className="text-xs px-2 ml-4 text-gray-100 pt-4 text-gray-400 italic font-medium">कोही भोको नपरुन्, कोही भोकले नमरुन</p>

      <div className="px-2 ml-1 border-b pb-4 border-gray-500">
        <IoClose onClick={() => setMobileOpen(false)} className="text-gray-100 text-4xl cursor-pointer absolute top-[13px] right-[8px]" />
        <Link
          to="/"
          className="-m-4 p-1.5 flex gap-1 pt-[20px] items-center ml-1"
          onClick={() => handleLinkClick('/')}
        >
          <img
            src="/assets/mahakal_logo.png"
            alt="logo"
            className="h-[40px] w-[40px] shadow-lg shadow-white rounded-full"
          />
          <p className="uppercase text-red drop-shadow-white font-bold font-lato w-[43%]" style={sideBarStyles}>{t('header.jayamahakal')}</p>
        </Link>
      </div>
      <ul className={`${sideBarStyles} px-8 space-y-6 pt-12`} style={sideBarStyles}>
        <li className="border px-2 py-1 border-gray-600 rounded-lg">
          <div className="relative">
            <button
              type="button"
              onClick={() => setIsAboutUsOpen(!isAboutUsOpen)}
              className="flex items-center space-x-2 w-full text-left"
            >
              <span className="uppercase">{t('header.aboutUs')}</span>
              <svg
                className={`w-5 h-5 transform transition-transform ${isAboutUsOpen ? 'rotate-180' : ''}`}
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {isAboutUsOpen && (
              <ul className="pl-2 mt-2 space-y-2">
                {features.map((feature) => (
                  <li key={feature.id} className="border px-1 py-1 border-gray-600 rounded-lg">
                    <Link
                      to={feature.href}
                      className="flex items-center gap-2"
                      style={sideBarStyles}
                      onClick={() => handleLinkClick(feature.href)}
                    >
                      <feature.icon className="text-xl" />
                      {t(`aboutUsMenu.${feature.name}`)}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </li>
        <li>
          <Link
            to="/media"
            className="flex items-center space-x-2 border px-2 py-1 border-gray-600 rounded-lg"
            onClick={() => handleLinkClick('/media')}
          >
            {t('header.media')}
          </Link>
        </li>
        <li>
          <Link
            to="/all-activities"
            className="flex items-center space-x-2 border px-2 py-1 border-gray-600 rounded-lg"
            onClick={() => handleLinkClick('/all-activities')}
          >
            {t('header.events')}
          </Link>
        </li>
        <li>
          <Link
            to="/membership"
            className="flex items-center space-x-2 border px-2 py-1 border-gray-600 rounded-lg"
            onClick={() => handleLinkClick('/membership')}
          >
            {t('header.becomeMember')}
          </Link>
        </li>
        <li>
          <Link
            to="/contact-us"
            className="flex items-center space-x-2 border px-2 py-1 border-gray-600 rounded-lg"
            onClick={() => handleLinkClick('/')}
          >
            {t('header.contactUs')}
          </Link>
        </li>
      </ul>
      {' '}

    </div>
  );
};
export default Sidebar;
