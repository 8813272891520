import React, { useState, memo } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Popover,
  Button,
} from '@headlessui/react';

import { FaBars } from 'react-icons/fa6';

import { Link } from 'react-router-dom';
import LanguageSwitcher from '../language-switcher';
import AboutMenu from './about-menu';
import Sidebar from './sidebar';
import getStyles from '../../util/style';

const Navbar = () => {
  const aBeforeClass = 'before:content-[\'\'] before:absolute before:left-0 before:bottom-[-5px] before:w-0 before:h-[2px] before:bg-gray-100 before:transition-all before:duration-300 hover:before:w-full';
  const aClasses = 'inline-block transition-all duration-500 font-semibold relative text-[1rem] leading-6 text-gray-200 hover:text-white font-lato';
  const [mobileOpen, setMobileOpen] = useState(false);
  const [setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const outerStyles = getStyles(i18n.language);
  const isReady = i18n.isInitialized;

  if (!isReady) {
    return null;
  }
  return (
    <>
      <nav
        className="z-50 uppercase  px-6 lg:px-12 bg-red fixed w-full top-0 font-poppins"
        aria-label="Global"
      >

        <Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} lng={i18n.language} />
        <div
          className=" w-full  font-medium text-sm  h-[70px]  flex items-center justify-between"
        >
          <div className="hidden lg:flex lg:flex-1">
            <Link to="/" className="flex items-center">
              <img
                src="/assets/mahakal_logo.png"
                alt="cookgpt_logo"
                className="h-[55px] min-w-[55px] shadow-lg shadow-white rounded-full space-x-4]"
              />
              <span className="uppercase  text-black ml-2 font-semibold" style={outerStyles}>
                {t('header.jayamahakal')}
                <p className="text-sm mt-1  italic text-gray-200 font-medium">कोही भोको नपरुन्, कोही भोकले नमरुन</p>
              </span>
            </Link>
          </div>
          <div className="lg:hidden lex lg:flex-1">
            <Link to="/">
              <img
                src="/assets/mahakal_logo.png"
                alt="cookgpt_logo"
                className="h-[30px] ml-8 min-w-[30px] shadow-lg shadow-white rounded-full"
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              aria-label="clickme"
              className=" inline-flex items-center  rounded-md  text-gray-200"
              onClick={() => setMobileOpen(true)}
            >
              <FaBars
                className="text-gray-100 h-8 w-8 border-0 text-2xl bg-transparent fixed top-20px left-20px"
                onClick={() => setMobileOpen(!mobileOpen)}
              />
            </button>
          </div>
          <Popover className="relative hidden lg:flex lg:gap-x-12 items-center">
            <AboutMenu />
            <Link
              to="/media"
              className={`${aBeforeClass} ${aClasses}`}
            >
              {t('header.media')}
            </Link>
            <Link to="/all-activities" className={`${aBeforeClass} ${aClasses}`}>
              {t('header.events')}
            </Link>
            <Link to="/membership" className={`${aBeforeClass} ${aClasses}`}>
              {t('header.becomeMember')}
            </Link>
            <Link to="/contact-us" className={`${aBeforeClass} ${aClasses} `}>
              {t('header.contactUs')}
            </Link>
          </Popover>

          <div className="flex lg:flex-1 lg:justify-end items-center gap-x-2">
            <LanguageSwitcher setisOpen={setIsOpen} />

            <div className=" gap-x-4">
              <Link to="/donate-us" className="text-sm leading-6 font-semibold text-white flex">

                <Button
                  type="button"
                  aria-label="user-btn"
                  className="blink-text uppercase text-sm  btn min-w-[150px] inline-flex font-poppins font-semibold"
                >
                  {t('header.donateNow')}
                  {' '}
                </Button>
                {' '}
              </Link>
            </div>
          </div>

        </div>
      </nav>
    </>
  );
};

export default memo(Navbar);
