import { I18nextProvider } from 'react-i18next';
import {
  BrowserRouter, Route, Routes, useLocation,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import i18n from './i18n';
import LandingPage from './components/landing-page/landing-page';
import AboutUs from './components/routes/about-us';
import Media from './components/routes/media';
import Footer from './components/footer/footer';
import PhotoGallery from './components/othercomponents/photo-gallery';
import VideoLibrary from './components/routes/video-library';
import NoticeBoard from './components/routes/notice-period';
import Membership from './components/routes/membership';
import Contact from './components/routes/contact-us';
import AllActivities from './components/routes/all-activities';
import Branches from './components/routes/branches';
import Success from './components/routes/success';
import DonateUs from './components/routes/donate-us';
import Profiles from './components/othercomponents/profile/profiles-teams';
import NotFound from './components/not-found';
import PageWrapper from './components/page-wraper';
import Navar from './components/header/navar';
import ScrollToTop from './components/scroll-top';

function App() {
  const location = useLocation();

  return (
    <div className="app">
      <I18nextProvider i18n={i18n}>
        <Navar />
        <AnimatePresence mode="wait">
          <ScrollToTop />
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<PageWrapper><LandingPage /></PageWrapper>} />
            <Route path="/about-us" element={<PageWrapper><AboutUs /></PageWrapper>} />
            <Route path="/media" element={<PageWrapper><Media /></PageWrapper>} />
            <Route path="/video-gallery" element={<PageWrapper><VideoLibrary /></PageWrapper>} />
            <Route path="/photo-gallery" element={<PageWrapper><PhotoGallery /></PageWrapper>} />
            <Route path="/notices" element={<PageWrapper><NoticeBoard /></PageWrapper>} />
            <Route path="/membership" element={<PageWrapper><Membership /></PageWrapper>} />
            <Route path="/contact-us" element={<PageWrapper><Contact /></PageWrapper>} />
            <Route path="/all-activities" element={<PageWrapper><AllActivities /></PageWrapper>} />
            <Route path="/our-branches" element={<PageWrapper><Branches /></PageWrapper>} />
            <Route path="/success_page" element={<PageWrapper><Success /></PageWrapper>} />
            <Route path="/donate-us" element={<PageWrapper><DonateUs /></PageWrapper>} />
            <Route path="/our-team" element={<PageWrapper><Profiles /></PageWrapper>} />
            <Route path="*" element={<PageWrapper><NotFound /></PageWrapper>} />
          </Routes>
          <ScrollToTop />
        </AnimatePresence>
        <Footer />
      </I18nextProvider>
    </div>
  );
}

const AppWrapper = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWrapper;
