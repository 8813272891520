import {
  FaFacebookSquare,
} from 'react-icons/fa';
import { IoLogoYoutube } from 'react-icons/io';
import { IoShareSocial } from 'react-icons/io5';

const SocialMediaIcons = () => {
  const currentUrl = window.location.href;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;

  return (
    <div className="flex justify-center md:justify-start my-2 gap-7">
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.facebook.com/Jayamahakalyouthgroupnepal"
        target="_blank"
        rel="noreferrer"
        aria-label="Facebook profile"
      >
        <FaFacebookSquare size={32} />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.youtube.com/@jayamahakalofficial6313"
        target="_blank"
        rel="noreferrer"
        aria-label="Youtube-profile"
      >
        <IoLogoYoutube size={32} />
      </a>
      <a
        className="hover:opacity-50 flex font-medium items-center gap-1 transition duration-500"
        href={facebookShareUrl}
        target="_blank"
        rel="noreferrer"
        aria-label="Share on Facebook"
      >
        <IoShareSocial size={32} />
        Share
      </a>

    </div>
  );
};
export default SocialMediaIcons;
