/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

const branches = [
  {
    id: 1, district: 'Bagmati', contactPerson: 'Not Available', mobile: '000000000',
  },
  {
    id: 2, district: 'Bake', contactPerson: 'Bhak B. B. K.', mobile: '9858034320',
  },
  {
    id: 3, district: 'Belauri', contactPerson: 'Madan Bohara', mobile: '984613233',
  },
  {
    id: 4, district: 'Bhimdatta', contactPerson: 'Not Available', mobile: '000000000',
  },
  {
    id: 5, district: 'Bijayanagar', contactPerson: 'Samsher Singh', mobile: '000000000',
  },
  {
    id: 6, district: 'Chitwan', contactPerson: 'Basudev Parajuli', mobile: '9814000936',
  },
  {
    id: 7, district: 'Damak', contactPerson: 'Nar B. Gurung', mobile: '9819077515',
  },
  {
    id: 8, district: 'Dang', contactPerson: 'Hemanta Basnet', mobile: '9857875500',
  },
  {
    id: 9, district: 'Dhading', contactPerson: 'Ashok Lama', mobile: '9749499936',
  },
  {
    id: 10, district: 'Dharan', contactPerson: 'Not Available', mobile: '000000000',
  },
  {
    id: 11, district: 'Gorkha', contactPerson: 'Not Available', mobile: '000000000',
  },
  {
    id: 12, district: 'Kanchanpur', contactPerson: 'Harka B. Bohora', mobile: '9848041991',
  },
  {
    id: 13, district: 'Kapilbastu', contactPerson: 'Dhanraj B. K.', mobile: '000000000',
  },
  {
    id: 29, district: 'Kohalpur', contactPerson: 'Tilak Pun', mobile: '000000000',
  },
  {
    id: 14, district: 'Kaski', contactPerson: 'Not Available', mobile: '000000000',
  },
  {
    id: 15, district: 'Kathnamdu District', contactPerson: 'Raju Thapa', mobile: '9866296473',
  },
  {
    id: 28, district: 'Kathnamdu Metro', contactPerson: 'Binod Dhakal', mobile: '9810276991',
  },
  {
    id: 16, district: 'Krishnapur', contactPerson: 'Khadga B. Khadka', mobile: '9810616427',
  },
  {
    id: 17, district: 'Lamahi', contactPerson: 'Sheshkant Adhikari', mobile: '9847826914',
  },
  {
    id: 30, district: 'Laljhaadi', contactPerson: 'Yogesh Dhami', mobile: '9867793630',
  },
  {
    id: 32, district: 'Lamjung', contactPerson: 'Rajesh Rana', mobile: '9823575708',
  },
  {
    id: 18, district: 'Maharajgunj', contactPerson: 'Rajesh Gupta', mobile: '000000000',
  },
  {
    id: 19, district: 'Mechi', contactPerson: 'Govinda Budathoki', mobile: '9814924965',
  },
  {
    id: 20, district: 'Nuwakot', contactPerson: 'Krishna Ghimire', mobile: '9845712589',
  },
  {
    id: 21, district: 'Rupendehi', contactPerson: 'Rabi Acharya', mobile: '000000000',
  },
  {
    id: 22, district: 'Sainamaina', contactPerson: 'Sagar Lamsal', mobile: '000000000',
  },
  {
    id: 23, district: 'Salyan', contactPerson: 'Keshab Lamichane', mobile: '9847844320',
  },
  {
    id: 24, district: 'Sarlahi', contactPerson: 'Jiban Shah', mobile: '9800800457',
  },
  {
    id: 25, district: 'Sindhuli', contactPerson: '', mobile: '',
  },
  {
    id: 26, district: 'Sunsari', contactPerson: 'Saugat Thapa', mobile: '000000000',
  },
  {
    id: 31, district: 'SuklaPhaat', contactPerson: 'Hari Bohora', mobile: '9848727202',
  },
  {
    id: 27, district: 'Tulsipur', contactPerson: 'Umesh Acharya', mobile: '9821577607',
  },
  // Add more branches as needed
];

const Branches = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();
  const filteredBranches = branches.filter((branch) => branch.district.toLowerCase().includes(searchTerm.toLowerCase()));
  const lastId = branches.length;
  return (
    <div className="lg:px-12 px-6 py-20  mt-12 pt-24  h-auto">
      <h2 className="text-4xl font-bold mb-6 text-center text-gray-100 section-heading">
        {t('aboutUsMenu.Our Brannches')}
        {' '}
        -
        {' '}
        {lastId}
      </h2>
      <div className="mb-6 text-center">
        <input
          type="text"
          className="p-2 border border-gray-300 rounded-lg w-full lg:w-1/3 bg-gray-200"
          placeholder="Search by branch name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {filteredBranches.map((branch) => (
          <div
            key={branch.id}
            className="p-4 border border-gray-300 rounded-lg shadow-md bg-gray-200"
          >
            <h3 className="text-xl font-bold mb-2 text-red">{t(`branchesDistrict.${branch.district}`)}</h3>
            <p className="text-gray-600">
              <strong className="hidden lg:block">
                {t('branches.contactPerson')}
                :
              </strong>
              {' '}
              {t(`branchesName.${branch.contactPerson}`)}
            </p>
            <p className="text-gray-600">
              <strong>{t('branches.mobile')}</strong>
              {' '}
              {branch.mobile}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Branches;
