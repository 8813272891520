import React from 'react';
import { useTranslation } from 'react-i18next';
import ProfileCard from './profile-card';
import profiles from './profile';

const Profiles = () => {
  const chairperson = profiles[0];
  const members = profiles.slice(1);
  const { t } = useTranslation();
  return (

    <div className="py-20 lg:border lg:border-gray-700 rounded-lg mt-28 mb-12 lg:w-3/5 mx-auto  h-auto flex flex-col justify-center items-center">
      <h1 className="text-4xl -mt-12 font-bold mb-8 section-heading text-gray-200">{t('aboutUsMenu.mahakalheading')}</h1>
      <div className="mb-8  grid grid-cols-1">
        <ProfileCard
          photo={chairperson.photo}
          name={chairperson.name}
          post={chairperson.post}
          mobile={chairperson.mobile}
          address={chairperson.address}
          email={chairperson.email}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
        {members.map((profile) => (
          <ProfileCard
            key={profile.id}
            photo={profile.photo}
            name={profile.name}
            post={profile.post}
            mobile={profile.mobile}
            address={profile.address}
          />
        ))}
      </div>
    </div>
  );
};

export default Profiles;
