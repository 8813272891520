import { useTranslation } from 'react-i18next';
import {
  PopoverButton, PopoverPanel, Transition,
} from '@headlessui/react';
import { HiMiniChevronDown } from 'react-icons/hi2';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { features } from '../../util/constant';

const AboutMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const aBeforeClass = 'before:content-[\'\'] before:absolute before:left-0 before:bottom-[-5px] before:w-0 before:h-[2px] before:bg-gray-100 before:transition-all before:duration-300 hover:before:w-full';
  const aClasses = 'inline-block transition-all duration-500 font-semibold relative text-[1rem] leading-6 text-gray-200 hover:text-white font-lato';
  const handleLinkClick = (href) => {
    setIsOpen(false);
    navigate(href);
  };
  return (
    <>
      <PopoverButton
        className={`${aBeforeClass} ${aClasses} uppercase flex items-center ml-2 inline-block outline-0 text-[14.5px] font-lato leading-6 text-gray-200 font-semibold`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span>{t('header.aboutUs')}</span>
        <span><HiMiniChevronDown className="h-8 w-7 text-gray-200" /></span>
      </PopoverButton>
      <Transition
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={isOpen}
      >
        <PopoverPanel anchor="bottom" className="absolute bg-gray-800 font-lato  -left-8 top-full z-10 mt-3  max-w-md overflow-hidden rounded-3xl shadow-lg ring-1 ring-gray-900/5">
          <div className="p-4">
            {features.map((item) => (
              <div key={item.id} className="group relative flex gap-x-2 items-center justify-center rounded-lg p-4 text-md hover:bg-gray-700">
                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-800 group-hover:bg-gray-600">
                  <item.icon className="h-8 w-8 text-red group-hover:text-[#ff8833]" aria-hidden="true" />
                </div>
                <div className="flex-auto">
                  <Link
                    to={item.href}
                    className="block font-semibold text-red"
                    onClick={() => handleLinkClick(item.href)}
                  >
                    {t(`aboutUsMenu.${item.name}`)}
                    <span className="absolute inset-0" />
                  </Link>
                  <p className="mt-1 text-gray-400 text-sm">
                    {t(`aboutUsDescription.${item.description}`)}
                  </p>
                </div>
              </div>
            ))}

          </div>
        </PopoverPanel>
      </Transition>
    </>
  );
};
export default AboutMenu;
