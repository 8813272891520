import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaCircleArrowRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="py-20 lg:bg-gray-200">
      <div className="container mx-auto">
        <div className="flex flex-wrap -mx-4">
          <div className="lg:w-1/2 px-4 mb-8 lg:mb-0">
            <div className="relative h-[400px] overflow-hidden">
              <img
                className="lg:absolute top-0 right-0 bg-white p-5 w-full h-full object-cover"
                src="../assets/about-us.jpeg"
                alt=""
              />
              <img
                className="absolute top-0 lg:right-0 right-4 bg-white p-2 w-[100px] h-[100px] lg:w-[200px] lg:h-[200px] object-cover"
                src="../assets/vulclean1.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="lg:w-1/2 px-4">
            <div className="inline-block rounded-full font-medium bg-red text-gray-100 py-1 px-4 mb-3">{t('header.aboutUs')}</div>
            <h1 className="text-5xl text-gray-200  lg:text-gray-800 font-bold mb-5">
              {t('header.aboutHeading')}
              {' '}
            </h1>
            <div className="bg-light text-gray-200 lg:text-gray-800  rounded p-4 mb-2">
              <p className="text-dark mb-2">
                {t('header.aboutMahakal')}

              </p>
              <p className="mb-2">
                {t('header.aboutMahakal1')}
              </p>
            </div>
            <div className="flex flex-col gap-4 lg:flex-row">
              <Link to="/about-us" className=" bg-red px-6 py-2 text-lg rounded-lg text-white rounded flex items-center lg:hover:bg-[#ff8833]">
                {t('header.btnLearnmore')}
                <div className="inline-flex btn-sm  text-gray-800 rounded-full ml-2">
                  <FaCircleArrowRight className="fill-white text-xl" />
                </div>
              </Link>
              <Link to="/contact-us" className="border text-red flex text-lg items-center border-red rounded-lg px-6 py-2 lg:hover:bg-gray-700">
                {t('header.btnContact')}
                <div className="inline-flex btn-sm bg-primary text-primary rounded-full ml-2">
                  <FaCircleArrowRight className="text-xl fill-red" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
