/* eslint-disable react/self-closing-comp */
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Button } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import FacebookPagePlugin from '../othercomponents/facebook-page';
import FacebookLivePlugin from '../othercomponents/facebook-live';
// Sample data for YouTube videos
const videos = [
  {
    id: 1,
    title: 'Prithvi Narayan Shah Birth Anniversary Celebration',
    description: 'Jaya Mahakal Youth Group is celebrating the birth anniversary of the great King Prithvi Narayan Shah',
    videoId: 'uSSIiZEofF8',
  },
  {
    id: 2,
    title: 'Free Food in Pashupatinath Temple',
    description: 'Feeding the hungry and quenching the thursty movement in Pashupatinath temple.',
    videoId: 'b9ZUAgJwPO4',
  },
  {
    id: 3,
    title: 'Empowering legless basketball',
    description: 'Jai Mahankal is empowering legless basketball players to achieve their dreams.',
    videoId: 'ML5xGMxrGQg',
  },
  // Add more videos as needed
];

const YouTubeSlider = () => {
  const { t } = useTranslation();
  return (
    <section id="youtube-videos" className="py-20 bg-black lg:mt-32">
      <div className="lg:container  lg:mx-auto text-center lg:border lg:border-gray-800 p-4 rounded-lg">
        <div className="flex justify-center flex-col lg:flex-row gap-8 mb-12">
          <span className="text-xl text-gray-100 font-medium ">
            {t('facebook.pageheading')}
            <FacebookPagePlugin />
          </span>
          <div className="text-xl text-[#ff0000] font-medium">
            {t('facebook.liveheading')}
            <FacebookLivePlugin />
            <a
              href="https://www.facebook.com/Jayamahakalyouthgroupnepal/live_videos"
              target="_blank"
              rel="noreferrer"
              className="text-center relative bottom-16 hover:text-blue"
            >
              <span className="text-sm underline px-4  text-center">{t('facebook.linkheading')}</span>

            </a>
          </div>
        </div>
        <h2 className="text-4xl font-bold mb-8 text-gray-200 section-heading">{t('videos.videoTitle')}</h2>
        <Swiper
          modules={[Pagination, Navigation]}
          spaceBetween={30}
          pagination={{ clickable: true }}
          navigation
          loop
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {videos.map((video) => (
            <SwiperSlide key={video.id}>
              <div className="relative px-2">
                <iframe
                  className="w-full h-64 border border-gray-700 rounded-lg"
                  src={`https://www.youtube.com/embed/${video.videoId}`}
                  title={video.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div className="absolute inset-0 flex items-center justify-start p-6 pointer-events-none">
                  <div className="bg-gradient-to-r from-black via-transparent to-transparent opacity-65 absolute inset-0 pointer-events-none"></div>
                  <div className="text-left relative z-10 pointer-events-none">
                    <h3 className="text-2xl font-bold text-deep-green mb-2">{t(`videosTitle.${video.title}`)}</h3>
                    <p className="text-gray-200">{t(`videosDescription.${video.description}`)}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <a href="https://youtube.com/@jayamahakalofficial6313?si=sCJLH_9tpb2GKnch">
          <Button className="mt-4 hover:bg-deep-green text-gray-100 bg-red py-2.5 px-16 rounded-lg text-lg">
            {t('videos.btnvideo')}
            &#8594;
          </Button>
        </a>
      </div>
    </section>
  );
};
export default YouTubeSlider;
