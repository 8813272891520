/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Media = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="lg:px-16 px-6 py-20  mt-12 pt-24 lg:h-screen h-auto">
      <h2 className="text-4xl font-bold mb-8 text-center section-heading w-full text-gray-100">{t('media.heading')}</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div
          className="card border border-gray-400 bg-black text-white rounded-lg shadow-md shadow-white cursor-pointer transform transition-transform duration-300 hover:scale-105"
          onClick={() => navigate('/video-gallery')}
        >
          <img src="../assets/video-gallery.png" alt="Video Gallery" className="w-full h-40 object-cover rounded-lg mb-4" />

          <div className="p-6 text-center">
            <h3 className="text-2xl font-bold">{t('media.videogallery')}</h3>
            <p className="mt-4">{t('media.videotext')}</p>
          </div>
        </div>
        <div
          className="card border border-gray-400 bg-blue text-white rounded-lg shadow-md shadow-white cursor-pointer transform transition-transform duration-300 hover:scale-105"
          onClick={() => navigate('/photo-gallery')}
        >
          <img src="../assets/photo-gallery.jpg" alt="" className="w-full h-40 object-cover rounded-t-lg mb-4" />

          <div className="p-6 text-center">
            <h3 className="text-2xl font-bold">{t('media.photogallery')}</h3>
            <p className="mt-4">{t('media.phototext')}</p>
          </div>
        </div>
        <div
          className="card border border-gray-400 bg-red text-white rounded-lg shadow-md shadow-white cursor-pointer transform transition-transform duration-300 hover:scale-105"
          onClick={() => navigate('/notices')}
        >
          <img src="../assets/notice-period.jpg" alt="Notices" className="w-full h-40 object-cover rounded-t-lg mb-4" />

          <div className="p-6 text-center">
            <h3 className="text-2xl font-bold">{t('media.notice')}</h3>
            <p className="mt-4">{t('media.noticetext')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Media;
