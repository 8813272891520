import React, { useEffect } from 'react';

const FacebookLivePlugin = () => {
  useEffect(() => {
    // Load the Facebook SDK for JavaScript
    const loadFacebookSDK = () => {
      if (window.FB) {
        window.FB.XFBML.parse();
        return;
      }

      // eslint-disable-next-line func-names
      window.fbAsyncInit = function () {
        window.FB.init({
          xfbml: true,
          version: 'v20.0',
        });
      };

      // Load the SDK script
      // eslint-disable-next-line func-names
      (function (d, s, id) {
        const js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_GB/sdk.js';
        const fjs = d.getElementsByTagName(s)[0];
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    };

    loadFacebookSDK();
  }, []);

  return (
    <div className="mt-4 border border-rose-400 rounded-lg p-1 w-[309px] mx-auto">
      <div id="fb-root" />
      <div
        className="fb-video fb-video-custom"
        data-href="https://www.facebook.com/Jayamahakalyouthgroupnepal/live_videos"
        data-width="360"
        data-height="340"
        data-show-text="false"
      >
        <blockquote cite="https://www.facebook.com/Jayamahakalyouthgroupnepal/live_videos" className="fb-xfbml-parse-ignore">
          <a
            href="https://www.facebook.com/Jayamahakalyouthgroupnepal/live_videos"
          >
            Watch Live Video on Facebook
          </a>
        </blockquote>
      </div>

    </div>
  );
};

export default FacebookLivePlugin;
