import { useTranslation } from 'react-i18next';

const BankDetails = () => {
  const { t } = useTranslation();
  return (
    <div className="">
      <div>
        <div className="inline-block rounded w-full lg:w-1/2 font-medium bg-red text-gray-100 py-1 px-4 mb-8">{t('formsuccess.qrcode')}</div>
        <img src="./assets/qr_code.jpg" alt="QR Code" className="h-32 w-32 mx-auto border-4 p-1 border-red rounded-lg mb-6" />
      </div>

      <div>
        <div className="inline-block rounded w-full lg:w-1/2 font-medium bg-red text-gray-100 py-1 px-4 mb-4">
          {t('formsuccess.bankdetails')}
        </div>
        <ul className="text-lg text-left flex flex-col justify-center items-center list-none">
          <li> Nepal Bank Limited , Lazimpat Branch.</li>
          <li> Ac Name : Jaya Mahakal Youth Group</li>
          <li className="font-bold"> Ac No : 05000106878660000001</li>
          <li>  E-Sewa - 9818088891, Khalti - 9841683737</li>
        </ul>

      </div>
      <div className="mt-4 inline-block rounded w-full lg:w-1/2 font-medium bg-red text-gray-100 py-1 px-4 mb-8">{t('formsuccess.qrcode2')}</div>
      <div className=" w-full lg:w-1/2 mx-auto">
        <img src="./assets/qr2.jpg" alt="QR Code" className="h-32 w-32 mx-auto border-4 p-1 border-red rounded-lg mb-6" />
        <ul className="text-lg  flex flex-col justify-center items-center">
          <li className="">
            Lumbini Bank Limited, Beni Branch
          </li>
          <li className=""> Ac Name : Jaya Mahakal Gau Ashram</li>
          <li className="font-bold"> Terminal : 2222290015077279</li>
        </ul>
      </div>
    </div>
  );
};
export default BankDetails;
