import { useTranslation } from 'react-i18next';
import DonateForm from '../othercomponents/donate-form';

const DonateNow = () => {
  const { t } = useTranslation();
  return (
    <div
      className="relative  bg-cover bg-center h-1/2 mb-16"
      style={{ backgroundImage: "url('../assets/donatenow.jpg')" }}
    >
      <div className="bg-black bg-opacity-50 absolute inset-0" />
      <div className="container mx-auto py-5 relative z-10">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/2 px-4 mb-5 lg:mb-0">
            <div className="bg-red ml-2 lg:ml-1 font-medium text-gray-100 py-1 px-4 mb-3 rounded-full inline-block">
              {t('header.donateNow')}
            </div>
            <h1 className="text-white text-4xl font-bold mb-5 px-2">
              {t('donateform.heading')}
            </h1>
            <p className="text-white mb-5 px-2">
              {t('donateform.text')}
            </p>

          </div>
          <div className="w-full lg:w-1/2 px-4">
            <div className="bg-white p-5 h-full rounded-lg shadow-md">
              <DonateForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonateNow;
