/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCircleArrowRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const DonateForm = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    amount: '',
    name: '',
    mobile: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const savePaymentData = async () => {
    const data = new FormData();
    data.append('amount', formData.amount);
    data.append('name', formData.name);
    data.append('mobile', formData.mobile);

    const SheetUrl = 'https://script.google.com/macros/s/AKfycbzQvBV8m2pIfXJJ5PXuGMNTudLp65-lhGlGzc_PbWaxAYiNDYFxpzMoxEVcokBfPmCk/exec';

    try {
      const response = await fetch(SheetUrl, {
        method: 'POST',
        body: data,
        muteHttpExceptions: true,
      });

      if (response.ok) {
        setFormData({
          amount: '',
          name: '',
          mobile: '',
        });
      } else {
        throw Error('Error submitting data');
      }
    } catch (error) {
      throw Error('Error:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await savePaymentData();
    setLoading(false);
    navigate('/success_page');
  };

  return (
    <form onSubmit={handleSubmit} className="py-2 max-w-lg mx-auto rounded-md">
      <div className="flex flex-col mb-4">
        <label htmlFor="amount" className="text-gray-700 font-semibold mb-2">
          {t('donateform.amount')}
        </label>
        <input
          type="number"
          name="amount"
          id="amount"
          value={formData.amount}
          onChange={handleChange}
          required
          className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="flex flex-col mb-4">
        <label htmlFor="name" className="text-gray-700 font-semibold mb-2">
          {t('donateform.name')}
        </label>
        <input
          type="text"
          name="name"
          id="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="flex flex-col mb-4">
        <label htmlFor="mobile" className="text-gray-700 font-semibold mb-2">
          {t('donateform.mobile')}
        </label>
        <input
          type="text"
          name="mobile"
          id="mobile"
          value={formData.mobile}
          onChange={handleChange}
          required
          className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <input type="hidden" name="tax_amount" value={formData.tax_amount} />
      <input type="hidden" name="total_amount" value={formData.total_amount} />

      <button
        type="submit"
        className="w-full mt-2 flex justify-center items-center bg-red hover:bg-[#ff8f1f] text-gray-100 py-3 rounded-md text-lg font-semibold transition duration-300"
      >
        <span className="uppercase">{loading ? 'Processing...' : t('donateform.pay')}</span>
        <FaCircleArrowRight className="fill-white ml-2 h-5 w-5" />
      </button>
    </form>
  );
};

export default DonateForm;
