import RecentActivities from '../landing-page/recent-activities';
import UpcomingActivities from '../othercomponents/upcoming-activities';

const AllActivities = () => (
  <div className="lg:px-12 px-6 py-20 space-y-16 mt-12 pt-24  h-auto">
    <RecentActivities />
    <UpcomingActivities />
  </div>
);
export default AllActivities;
