import React from 'react';
import { useTranslation } from 'react-i18next';

const programs = [
  {
    sn: 1,
    programName: 'Feeding the Hungry Quenching the Thirsty',
    operationDistrict: '77 Districts',
    estimateCost: '5 Crore',
    misc: 'Daily morning and evening',
  },
  {
    sn: 2,
    programName: 'Green and Clean Nepal Movement',
    operationDistrict: '77 Districts',
    estimateCost: '2 Crore',
    misc: 'Nature conservation movement',
  },
  {
    sn: 3,
    programName: 'Cow Protection/Home Shelter Movement',
    operationDistrict: 'Kanchanpur District',
    estimateCost: '3 Crore',
    misc: 'Cow protection movement',
  },
];

const ProgramsTable = () => {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto py-8 px-2">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-red">
              <th className="py-2 px-4 text-left text-gray-100 text-xs lg:text-base uppercase font-semibold">{t('table.sn')}</th>
              <th className="py-2 px-4  text-left text-gray-100 text-xs lg:text-base uppercase font-semibold">{t('table.programnane')}</th>
              <th className="py-2 px-4 text-left text-gray-100 text-xs lg:text-base uppercase font-semibold">{t('table.opdistrict')}</th>
              <th className="py-2 px-4  text-left text-gray-100 text-xs lg:text-base uppercase font-semibold">{t('table.costestimate')}</th>
              <th className="py-2 px-4  text-left text-gray-100 text-xs lg:text-base uppercase font-semibold">{t('table.description')}</th>
            </tr>
          </thead>
          <tbody>
            {programs.map((program) => (
              <tr key={program.sn} className="border-b">
                <td className="py-2 px-4 bg-gray-700 text-gray-100 text-xs lg:text-sm">{program.sn}</td>
                <td className="py-2 px-4 bg-gray-700  text-gray-100 text-xs lg:text-sm">{t(`programnames.${program.programName}`)}</td>
                <td className="py-2 px-4 bg-gray-700  text-gray-100 text-xs lg:text-sm">{t(`opd.${program.operationDistrict}`)}</td>
                <td className="py-2 px-4 bg-gray-700 text-gray-100 text-xs lg:text-sm">{t(`estcost.${program.estimateCost}`)}</td>
                <td className="py-2 px-4 bg-gray-700 text-gray-100 text-xs lg:text-sm">{t(`description.${program.misc}`)}</td>
              </tr>
            ))}
            <tr className="border-t font-semibold bg-red ">
              <td className="py-2 px-4 text-gray-100 text-sm lg:text-base" colSpan="3">{t('table.total')}</td>
              <td className="py-2 px-4 text-gray-100 text-sm lg:text-base">{t('table.10crore')}</td>
              <td className="py-2 px-4 text-gray-100 text-sm lg:text-base" />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ProgramsTable;
