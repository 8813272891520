const profiles = [
  {
    id: 1,
    name: 'Lilanath Adhikari',
    post: 'Chairperson',
    mobile: '9841683737',
    address: 'Kathmandu',
    photo: './assets/profiles/cp.png',
    email: '',
  },
  {
    id: 2,
    name: 'Bal B. Koirala',
    post: 'Vice Chairperson',
    mobile: 'NA',
    address: 'Kathmandu',
    photo: './assets/profiles/avatar.jpg',
    email: 'notavailable@gmail.com',

  },
  {
    id: 3,
    name: 'Narayan Khatri',
    post: 'Vice Chairperson',
    mobile: '9841127970',
    address: 'Kathmandu',
    photo: './assets/profiles/nrk.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 4,
    name: 'Manish B.K.',
    post: 'General Secretary',
    mobile: '9818088891',
    address: 'Dharan',
    photo: './assets/profiles/gs.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 5,
    name: 'Ashok B.K.',
    post: 'Treasurer',
    mobile: '9851180886',
    address: 'Dharan',
    photo: './assets/profiles/ashok.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 6,
    name: 'Mahesh Rana',
    post: 'Youth Coordinator',
    mobile: '9767549394',
    address: 'Lamjung',
    photo: './assets/profiles/p5.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 7,
    name: 'Raju Thapa',
    post: 'Transport Dept. Head',
    mobile: '9866296473',
    address: 'Kathmandu',
    photo: './assets/profiles/p3.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 8,
    name: 'Praveen Bhusal(Paras)',
    post: 'Student Dept. Head',
    mobile: '9845330168',
    address: 'Rupandehi',
    photo: './assets/profiles/pb.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 25,
    name: 'Asish Raj Pandey',
    post: 'Protector',
    mobile: 'NA',
    address: 'Kathmandu',
    photo: './assets/profiles/samrak.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 9,
    name: 'Pursottam Giri',
    post: 'Advisor',
    mobile: '984-9990123',
    address: 'Dang',
    photo: './assets/profiles/pur.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 10,
    name: 'Kumar Tamang',
    post: 'Advisor',
    mobile: '9808946140',
    address: 'Kathmandu',
    photo: './assets/profiles/kumar.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 11,
    name: 'Suresh Pardhe',
    post: 'Advisor',
    mobile: '9860187852',
    address: 'Dang',
    photo: './assets/profiles/pardhe.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 23,
    name: 'Janak Dhungana',
    post: 'Advisor',
    mobile: '986-0119215',
    address: 'Kathmandu',
    photo: './assets/profiles/janak.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 12,
    name: 'Binod Dhakal',
    post: 'Central member',
    mobile: '981-0276591',
    address: 'Kathmandu',
    photo: './assets/profiles/binod.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 13,
    name: 'Baba Parajuli',
    post: 'Central member',
    mobile: '9814000939',
    address: 'Chitwan',
    photo: './assets/profiles/p6.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 14,
    name: 'Pramod Bhattrai',
    post: 'Central member',
    mobile: '982-8965050',
    address: 'Nuwakot',
    photo: './assets/profiles/promod.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 13,
    name: 'Rajesh Rana',
    post: 'Central member',
    mobile: '9823575708',
    address: 'Lamjung',
    photo: './assets/profiles/p4.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 14,
    name: 'Arjun Panta',
    post: 'Central member',
    mobile: '9868883155',
    address: 'Kanchanpur',
    photo: './assets/profiles/p1.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 15,
    name: 'Bhuban G.C.',
    post: 'Central member',
    mobile: '9748709357',
    address: 'Dang',
    photo: './assets/profiles/bgc.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 16,
    name: 'Hikmat Yer',
    post: 'Central member',
    mobile: '9766424395',
    address: 'Kanchanpur',
    photo: './assets/profiles/hikmat.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 17,
    name: 'Raju Upreti',
    post: 'Central member',
    mobile: 'NA',
    address: 'Nuwakot',
    photo: './assets/profiles/avatar.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 18,
    name: 'Ghanashyam Bohora',
    post: 'Central member',
    mobile: '9849116845',
    address: 'Baitaidi',
    photo: './assets/profiles/p2.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 19,
    name: 'Kamal Karki',
    post: 'Central member',
    mobile: '9851345390',
    address: 'Gulmi',
    photo: './assets/profiles/kamalkarki.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 20,
    name: 'Bharat Raj Mahatara',
    post: 'Central member',
    mobile: '982-9559338',
    address: 'Salyan',
    photo: './assets/profiles/bharatm.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 21,
    name: 'Dhirendra Saud',
    post: 'Central member',
    mobile: '976-1785027',
    address: 'Baitaidi',
    photo: './assets/profiles/dhi.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 21,
    name: 'Bikash B. Adhikari',
    post: 'Central member',
    mobile: '9860342599',
    address: 'Kakadvitta',
    photo: './assets/profiles/bikashb.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 24,
    name: 'Pradip Khawas',
    post: 'Central member',
    mobile: '980-4980844',
    address: 'Mechi',
    photo: './assets/profiles/pradip.jpg',
    email: 'notavailable@gmail.com',
  },
  {
    id: 22,
    name: 'Bibek Thapa',
    post: 'Child Chairperson',
    mobile: '9814000939',
    address: 'Kathmandu',
    photo: './assets/profiles/bibek.jpg',
    email: 'notavailable@gmail.com',
  },
  // Add more profiles as needed
];
export default profiles;
