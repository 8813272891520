import { useTranslation } from 'react-i18next';
import DonateNow from '../landing-page/donate-now';
import BankDetails from '../othercomponents/bank-details';

const DonateUs = () => {
  const { t } = useTranslation();
  return (
    <div
      className="lg:px-16 px-6 py-20  mt-12 pt-24  h-auto"
    >
      <DonateNow />
      <div
        className="bg-yellow rounded-b-lg lg:full mx-auto p-4 text-center -mt-16"

      >
        <p className="capitalize text-2xl lg:text-4xl mb-4 section-heading font-bold text-gray-800">{t('formsuccess.donateheading')}</p>
        <BankDetails />

      </div>
    </div>
  );
};
export default DonateUs;
