import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgramsList, { ProgramObjectives } from '../othercomponents/programs';
import ProgramsTable from '../othercomponents/program-table';

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="py-20 lg:bg-gray-200 mt-12 pt-24 h-auto">
      <div className=" px-6 lg:px-12">
        <div className="flex flex-wrap -mx-4">
          <div className="lg:w-1/2 px-4 mb-8 lg:mb-0">
            <div className="relative h-[400px] overflow-hidden">
              <img
                className="lg:absolute top-0 right-0 bg-white p-5 w-full h-full object-cover"
                src="../assets/about-us.jpeg"
                alt=""
              />
              <img
                className="absolute top-0 lg:right-0 right-4 bg-white p-2 w-[100px] h-[100px] lg:w-[200px] lg:h-[200px] object-cover"
                src="../assets/vulclean1.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="lg:w-1/2 px-4">
            <div className="inline-block rounded-full font-medium bg-red text-gray-100 py-1 px-4 mb-8">{t('header.aboutUs')}</div>
            <h1 className="text-5xl text-gray-200  lg:text-gray-800 font-bold">
              {t('header.aboutHeading')}
              {' '}
            </h1>
            <div className="bg-light text-gray-200 lg:text-gray-800  rounded p-4 mb-2">
              <p className="text-dark mb-2">
                {t('header.aboutMahakal')}

              </p>
              <p className="mb-2">
                {t('header.aboutMahakal1')}
              </p>
            </div>
          </div>
          {/* Second part */}

          <div className="lg:w-1/2 px-4">
            <div className="inline-block rounded-full font-medium bg-red text-gray-100 py-1 mt-12 px-4 mb-3">{t('header.programs')}</div>
            {/* <h1 className="text-5xl text-gray-200  lg:text-gray-800 font-bold mb-5">
              {t('header.programs')}
              {' '}
            </h1> */}
            <div className="bg-light text-gray-200 lg:text-gray-800 rounded p-4 mb-2">
              <ProgramsList />
            </div>
          </div>
          <div className="lg:w-1/2 px-4 mb-8 lg:mb-0">
            <div className="relative h-[400px] overflow-hidden">
              <img
                className="lg:absolute top-0 right-0 bg-white p-5 w-full h-full object-cover"
                src="../assets/aboutimg.jpeg"
                alt=""
              />
            </div>
            <div className="relative h-[300px] overflow-hidden mt-4">
              <img
                className="lg:absolute top-0 right-0 bg-white p-5 w-full  object-cover"
                src="../assets/chairp.jpeg"
                alt=""
              />
            </div>
          </div>
          <div className="lg:w-1/2 px-4">
            <div className="inline-block rounded-full font-medium bg-red text-gray-100 py-1 px-4 mb-3">{t('header.objectives')}</div>
            {/* <h1 className="text-5xl text-gray-200  lg:text-gray-800 font-bold mb-5">
              {t('header.programs')}
              {' '}
            </h1> */}
            <div className="bg-light text-gray-200 lg:text-gray-800  rounded p-4 mb-2">
              <ProgramObjectives />
            </div>
          </div>
          <div className="lg:w-1/2 px-4 mb-8 lg:mb-0 w-full">
            <div className="relative h-[400px] overflow-hidden">
              <img
                className="lg:absolute top-0 right-0 bg-white p-5 w-full h-full object-cover"
                src="../assets/group.jpeg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="inline-block rounded-full font-medium bg-red text-gray-100 py-1 mt-12 px-4">{t('header.tableheader')}</div>
      </div>
      <ProgramsTable />
    </div>
  );
};
export default AboutUs;
