import React from 'react';
import { useTranslation } from 'react-i18next';

const programs = [
  {
    title: 'Food Donation',
    description: 'Preparing and distributing nutritious meals to those in need.',
    image: '../assets/fd.avif',
  },
  {
    title: 'Distribute Free Clean Water',
    description: 'Ensuring access to clean and safe drinking water.',
    image: '../assets/cw.jpeg',
  },
  {
    title: 'Shelter and Housing',
    description: 'Offering shelter and housing solutions for the homeless.',
    image: '../assets/sh.png',
  },
];

const Programs = () => {
  const { t } = useTranslation();
  return (
    <section id="programs" className="py-20">
      <div className="lg:container lg:mx-auto text-center">
        <h2 className="section-heading text-4xl text-white font-bold mb-8">{t('featurePrograms.title')}</h2>
        <div className="flex  flex-wrap justify-center">
          {programs.map((program) => (
            <div key={program.title} className="w-full md:w-1/3 px-4 mb-8">

              <div className="p-6 bg-white rounded-lg shadow">
                <img src={program.image} alt={program.title} className="w-full  h-48 object-cover mb-4 rounded" />
                <h3 className="text-2xl font-bold mb-4">
                  {t(`featurePrograms.${program.title}`)}
                </h3>
                <p className="min-w-lg min-h-[30px]">{t(`programsDescription.${program.description}`)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Programs;
