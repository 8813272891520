export const slidesLg = [

  { id: 1, path: '../assets/carouselimages/m1.jpg' },
  { id: 2, path: '../assets/carouselimages/m2.jpg' },
  { id: 3, path: '../assets/carouselimages/m3.jpg' },
  { id: 4, path: '../assets/carouselimages/m444.jpg' },
  { id: 5, path: '../assets/carouselimages/m5.jpg' },
  { id: 6, path: '../assets/carouselimages/gousala.jpg' },

];
export const slidesSm = [

  { id: 4, path: '../assets/carouselimages/mobilescreen/img5.jpg' },
  { id: 8, path: '../assets/carouselimages/mobilescreen/img8.jpg' },
  { id: 2, path: '../assets/carouselimages/mobilescreen/img4.jpg' },
  { id: 3, path: '../assets/carouselimages/mobilescreen/img3.jpg' },
  { id: 12, path: '../assets/carouselimages/mobilescreen/gousalam.jpg' },
  { id: 4, path: '../assets/carouselimages/mobilescreen/img2.jpg' },
  { id: 5, path: '../assets/carouselimages/mobilescreen/mobile67.jpg' },
  { id: 6, path: '../assets/carouselimages/mobilescreen/img6.jpg' },
  { id: 7, path: '../assets/carouselimages/mobilescreen/img7.jpg' },
  { id: 9, path: '../assets/carouselimages/mobilescreen/img9.jpg' },
  { id: 10, path: '../assets/carouselimages/mobilescreen/hario.jpg' },
  { id: 11, path: '../assets/carouselimages/mobilescreen/krishi.jpg' },

];
