/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const photos = [
  'https://i.imgur.com/KsyEdlN.jpg',
  'https://i.imgur.com/LRXVDUG.jpeg',
  'https://i.imgur.com/FEDSF8m.jpeg',
  'https://i.imgur.com/9LMM7Vp.jpeg',
  'https://i.imgur.com/IAecAvs.jpeg',
  'https://i.imgur.com/o3vRwFj.jpeg',
  'https://i.imgur.com/96P2e7g.jpeg',
  'https://i.imgur.com/6LTUGRM.jpeg',
  'https://i.imgur.com/RtDDqgm.jpeg',
  'https://i.imgur.com/2O98R2u.jpeg',
  'https://i.imgur.com/6pUlhOT.jpeg',
  'https://i.imgur.com/7f4bd3x.jpeg',
  'https://i.imgur.com/s981CN6.jpeg',
  'https://i.imgur.com/hZRGBUS.jpeg',
  'https://i.imgur.com/x2gG63c.jpeg',
  'https://i.imgur.com/n2EpRYM.jpeg',
  'https://i.imgur.com/rx3DFoJ.jpeg',
  'https://i.imgur.com/LPDeA6b.jpeg',
  'https://i.imgur.com/z2EZM1M.jpeg',
  'https://i.imgur.com/a5Cp1qi.jpeg',
  'https://i.imgur.com/pU4NqSZ.jpeg',
  'https://i.imgur.com/Oc86kGQ.jpeg',
  'https://i.imgur.com/IP8AOuL.jpeg',
  'https://i.imgur.com/oXW3u30.jpeg',
  'https://i.imgur.com/ppFaLmN.jpeg',
  'https://i.imgur.com/9kMCXUN.jpeg',
  'https://i.imgur.com/MlKl636.jpeg',
];

const PhotoGallery = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const photosPerPage = 20;

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - photosPerPage, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + photosPerPage, photos.length - photosPerPage));
  };

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };

  const handleCloseModal = () => {
    setSelectedPhoto(null);
  };

  const currentPhotos = photos.slice(currentIndex, currentIndex + photosPerPage);

  return (
    <div className="lg:px-16 px-6 py-20  mt-12 pt-24 h-auto bg-gray-200">
      <div className="flex justify-end">
        <button
          type="button"
          className="bg-gray-700 hover:bg-gray-800 text-gray-200 px-8 py-1 rounded-lg mb-1"
          onClick={() => navigate('/media')}
        >
          Go Back
        </button>
      </div>

      <h2 className="text-3xl font-bold mb-8 text-center bg-red text-gray-200 rounded-lg">{t('media.photogallery')}</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {currentPhotos.map((photo, index) => (
          <img
            key={index}
            src={photo}
            alt={`Gallery Image ${currentIndex + index + 1}`}
            className="w-full h-full object-cover rounded-lg shadow-md cursor-pointer"
            onClick={() => handlePhotoClick(photo)}
          />
        ))}
      </div>
      <div className="flex justify-between mt-8">
        <button
          onClick={handlePrevious}
          className="bg-gray-700 hover:bg-gray-800 text-white p-2 rounded-full focus:outline-none"
          disabled={currentIndex === 0}
          type="button"
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          type="button"
          className="bg-gray-700 hover:bg-gray-800 text-white p-2 rounded-full focus:outline-none"
          disabled={currentIndex + photosPerPage >= photos.length}
        >
          Next
        </button>
      </div>
      {/* Modal */}
      {selectedPhoto && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative">
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-0 right-0 bg-gray-800 text-white p-2 rounded-full focus:outline-none"
            >
              Close
            </button>
            <img src={selectedPhoto} alt="Selected" className="lg:w-1/2 lg:h-1/2 mx-auto" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;
