// components/HeroSlider.js
// import Swiper core and required modules
import {
  Navigation, Pagination, Autoplay,
} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useEffect, useState } from 'react';
import { slidesLg, slidesSm } from './slide-image-data';

const HeroSlider = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const slides = windowWidth > 768 ? slidesLg : slidesSm;
  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      loop
      className="hero-slider"
    >
      {slides.map((slide) => (
        <SwiperSlide key={slide.id}>
          <img
            src={slide.path}
            alt={slide.alt}
            style={{ width: '100%', objectFit: 'cover' }}
            className="opacity-75"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default HeroSlider;
