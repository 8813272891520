import React from 'react';
import { useTranslation } from 'react-i18next';

const ProgramsList = () => {
  const { t } = useTranslation();
  const programs = [
    {
      id: 1, title: 'Distribution of Free Meals to the Poor', description: '', details: 'Alleviating hunger by distributing nutritious meals to impoverished individuals and families.',

    },
    {
      id: 2, title: 'Cow Protection Campaign', description: '', details: 'Promoting the welfare and protection of cows, ensuring their humane treatment and care.',
    },
    {
      id: 3, title: 'Free Health Camp Campaign', description: '', details: 'Providing free medical check-ups and healthcare services to underserved communities to improve public health.',
    },
    {
      id: 4, title: 'Public Awareness Program Against Illegal Drugs', description: '', details: 'Educating the public about the dangers of illegal drugs and promoting a drug-free society.',
    },
    {
      id: 5, title: 'Rescue Campaign for Citizens Affected by Earthquakes and Floods', description: '', details: 'Offering emergency relief and support to those impacted by natural disasters, ensuring their safety and recovery.',
    },
    {
      id: 6, title: 'School Admission and Educational Materials for Deprived Citizens', description: '', details: 'Facilitating school admissions and providing essential educational materials to children lacking access to education.',
    },
    {
      id: 7, title: 'Agriculture Campaign', description: '', details: 'Enhancing crop yields and boosting agricultural productivity through effective weed management.',
    },
    {
      id: 8, title: 'Conservation of Monasteries and Temples', description: '', details: 'Preserving and maintaining historic monasteries and temples to safeguard cultural and religious heritage.',
    },
    {
      id: 9, title: 'Door-to-Door Food Relief', description: '', details: 'Delivering food supplies directly to the homes of those in need, ensuring no one goes hungry.',
    },
  ];

  return (
    <div>
      {programs.map((program) => (
        <div key={program.id} className="">
          <h3 className="font-bold mb-2">{t(`programsTitle.${program.title}`)}</h3>
          <p className="">{t(`programDetails.${program.details}`)}</p>
          <p className="mb-2">{program.description}</p>

        </div>
      ))}
    </div>
  );
};

export default ProgramsList;
export const ProgramObjectives = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ul className="font-semibold space-y-2 list-disc px-4">
        <li>
          {t('programDetails.obj1')}
        </li>
        <li>
          {t('programDetails.obj2')}
        </li>
        <li>
          {t('programDetails.obj3')}
        </li>
        <li>
          {t('programDetails.obj4')}
        </li>
        <li>
          {t('programDetails.obj5')}
        </li>
        <li>
          {t('programDetails.obj6')}
        </li>
        <li>
          {t('programDetails.obj7')}
        </li>
        <li>
          {t('programDetails.obj8')}
        </li>
        <li>
          {t('programDetails.obj9')}
        </li>
      </ul>
    </div>
  );
};
