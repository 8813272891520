import React from 'react';
import { useTranslation } from 'react-i18next';

const ProfileCard = ({
  photo, name, post, mobile, address,
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-[340px] h-[160px] m-2 rounded-lg bg-gray-600  text-center shadow-sm hover:shadow-lg shadow-white hover:shadow-red">
      <div className="flex justify-between font-lato">
        <div className="flex flex-col items-center justify-center w-full">
          <h3 className="font-extrabold  text-xl px-1 text-center text-yellow">{t(`profilesName.${name}`)}</h3>
          <p className="text-gray-200 text-base font-semibold">{t(`profilesPost.${post}`)}</p>
          <p className="text-gray-300 text-base">{t(`profilesMobile.${mobile}`)}</p>
          {/* <p className="text-gray-300 text-xs">{t(`profilesEmail.${email}`)}</p> */}

          <p className="text-gray-300 text-base">{t(`profilesAddress.${address}`)}</p>
        </div>
        <img className="w-[165px] h-[160px] rounded-r-lg mb-4 object-cover" src={photo} alt={`${name}'s profile`} />
      </div>
    </div>
  );
};
export default ProfileCard;
