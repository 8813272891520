import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const notices = [
  {
    id: 1,
    subject: 'Annual Estimated Cost Plan',
    publishedDate: '2024-08-03',
    expirationDate: '2025-08-04',
    content: 'We are pleased to inform you about the annual meeting...',
    pdfLink: '../assets/annual_budget_plan.pdf',
  },
  {
    id: 2,
    subject: 'Quarterly Financial Report',
    publishedDate: '2024-07-05',
    expirationDate: '2025-07-31',
    content: 'Please find the attached quarterly financial report...',
    pdfLink: '/path/to/quarterly-financial-report.pdf',
  },
  // Add more notices as needed
];

const NoticeBoard = () => {
  const { t } = useTranslation();
  const [selectedNotice, setSelectedNotice] = useState(null);
  const navigate = useNavigate();
  return (
    <div className="container mx-auto py-10 h-screen  mt-12 pt-24">
      <h2 className="text-4xl font-bold mb-8 text-center section-heading text-gray-200">{t('media.noticeboard')}</h2>
      <div className="flex justify-end">
        <button
          type="button"
          className="bg-gray-700 hover:bg-gray-800 text-gray-200 px-8 py-1 rounded-lg mb-1"
          onClick={() => navigate('/media')}
        >
          Go Back
        </button>
      </div>

      <table className="min-w-full bg-white text-center">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">{t('notice.publishdate')}</th>
            <th className="py-2 px-4 border-b">{t('notice.subject')}</th>
            <th className="py-2 px-4 border-b">{t('notice.expiredate')}</th>
          </tr>
        </thead>
        <tbody>
          {notices.map((notice) => (
            <tr key={notice.id} onClick={() => setSelectedNotice(notice)} className="cursor-pointer hover:bg-gray-100">
              <td className="py-2 px-4 border-b">{t(`publishdate.${notice.publishedDate}`)}</td>
              <td className="py-2 px-4 border-b text-blue">{t(`noticesubject.${notice.subject}`)}</td>
              <td className="py-2 px-4 border-b">{t(`expiredate.${notice.expirationDate}`)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedNotice && (
        <div className="mt-10 p-6 bg-gray-100 rounded-lg shadow-md">
          <h3 className="text-2xl font-bold mb-4">{t(`noticesubject.${selectedNotice.subject}`)}</h3>
          <p className="mb-4">{t(`content.${selectedNotice.content}`)}</p>
          <a href={selectedNotice.pdfLink} className="text-blue underline" target="_blank" rel="noopener noreferrer">
            {t('notice.download')}
            {' '}
            PDF
          </a>
          <button
            type="button"
            onClick={() => setSelectedNotice(null)}
            className="mt-4 py-2 px-4 bg-red-500 text-red rounded hover:bg-red-600"
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default NoticeBoard;
