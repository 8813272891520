import Hero from '../hero/hero';
import UpcomingActivities from '../othercomponents/upcoming-activities';
import About from './about';
import DonateNow from './donate-now';
import HelpingHands from './helpinghands';
import Programs from './programs';
import RecentActivities from './recent-activities';
import YouTubeSlider from './video-slider';

const LandingPage = () => (
  <div className="">
    <Hero />
    <div className="px-6 lg:px-0">
      <About />
    </div>

    <div className="px-6 lg:px-12">

      <Programs />
      <RecentActivities />
    </div>
    <YouTubeSlider />
    <div className="px-6 lg:px-12">
      <UpcomingActivities />
      <HelpingHands />
      <DonateNow />
    </div>
  </div>
);
export default LandingPage;
