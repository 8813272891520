import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@headlessui/react';
import { Link } from 'react-router-dom';

// Sample data for upcoming activities
const activities = [
  {
    id: 1,
    title: 'Preparedness against natural disasters',
    date: '2024',
    time: '10:00 AM - 5:00 PM',
    location: 'All over the country, Nepal',
    contact: '01-5910176, 9841683737',
  },
  {
    id: 2,
    title: 'agriculture campaign',
    date: '2024',
    time: '9:00 PM - 5:00 PM',
    location: 'All over the country, Nepal',
    contact: '01-5910176, 9818088191',
  },
  {
    id: 3,
    title: 'Cow Protection Campaign',
    date: '2024',
    time: '9:00 AM - 5:00 PM',
    location: 'All over the country, Nepal',
    contact: '01-5910176, 9818088191',
  },
  {
    id: 4,
    title: 'Student Problem Solve',
    date: '2024',
    time: '9:00 AM - 5:00 PM',
    location: 'All over the country, Nepal',
    contact: '01-5910176, 9818088191',
  },
  {
    id: 4,
    title: 'Skillful Programs',
    date: '2024',
    time: '9:00 AM - 5:00 PM',
    location: 'All over the country, Nepal',
    contact: '01-5910176, 9818088191',
  },
  {
    id: 5,
    title: 'Cultural Heritage Protection',
    date: '2024',
    time: '9:00 AM - 5:00 PM',
    location: 'All over the country, Nepal',
    contact: '01-5910176, 9818088191',
  },
  {
    id: 5,
    title: 'Protection of Baidik Sanaatani Religion',
    date: '2024',
    time: '9:00 AM - 5:00 PM',
    location: 'All over the country, Nepal',
    contact: '01-5910176, 9818088191',
  },
  {
    id: 5,
    title: 'Health and Sports Promotion',
    date: '2024',
    time: '9:00 AM - 5:00 PM',
    location: 'All over the country, Nepal',
    contact: '01-5910176, 9818088191',
  },
  {
    id: 5,
    title: 'Free Education Campaign',
    date: '2024',
    time: '9:00 AM - 5:00 PM',
    location: 'All over the country, Nepal',
    contact: '01-5910176, 9818088191',
  },
  // Add more activities as needed
];
const MoreUpcomingActivities = () => {
  const { t } = useTranslation();
  return (

    <div className="">

      <ul className="">
        {activities.map((activity) => (
          <li key={activity.id} className=" rounded-lg items-center bg-white shadow-md mb-2 flex justify-between  rounded-lg overflow-hidden">
            <div className="w-1/2   bg-deep-green text-white p-4 ">
              <p>{activity.date}</p>
              <p>
                {' '}
                {activity.time}
              </p>

            </div>
            <div className="w-2/3 p-4 bg-white flex flex-col  justify-end bg-blue">
              <p className="font-bold">{t(`morerecentactivitiesTitlle.${activity.title}`)}</p>
              <p>
                {t(`upcomingActivities.${activity.location}`)}
                ,
                {' '}
                {activity.contact}
              </p>
            </div>
          </li>
        ))}
      </ul>
      <Link
        to="https://www.facebook.com/Jayamahakalyouthgroupnepal"
        className="flex items-center justify-center"
      >
        <Button className="mt-4 hover:bg-[#ff8833] text-gray-100 bg-red py-2.5 px-12 rounded-lg   text-lg">
          {t('upcomingActivities.btnseeactivity')}
          {' '}
          &#8594;
        </Button>
      </Link>
    </div>
  );
};
export default MoreUpcomingActivities;
