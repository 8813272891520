import { useTranslation } from 'react-i18next';
import { IoLocationOutline } from 'react-icons/io5';
import { MdLocalPhone, MdOutlineEmail, MdOutlineArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import SocialMediaIcons from '../othercomponents/social-media';

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <div className="text-white  w-full px-6 lg:px-12 border-t border-gray-600">
      <div className="py-5">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="text-center lg:text-left">
            <h1 className="uppercase font-bold text-gray-100 mb-4 text-lg">{t('header.jayamahakal')}</h1>
            <p className="text-gray-300">
              {t('header.aboutMahakal')}
            </p>
            <div className="flex justify-center lg:justify-start pt-2">
              <SocialMediaIcons />
            </div>
          </div>
          <div className="text-center lg:text-left">
            <h5 className="text-light mb-4 text-lg ">{t('footer.address')}</h5>
            <p className="text-gray-300 flex justify-center lg:justify-start text-[15px] items-center">
              <IoLocationOutline className="text-xl mr-1" />
              {t('footer.address1')}
            </p>
            <p className="text-gray-300 flex justify-center lg:justify-start text-[15px] items-center">
              <MdLocalPhone className="text-xl mr-1" />
              {t('footer.phone')}
            </p>
            <p className="text-gray-300 flex justify-center lg:justify-start text-[15px] items-center">
              <MdOutlineEmail className="text-xl mr-1" />
              {t('footer.email')}
            </p>
          </div>
          <div className="text-center lg:text-left">
            <h5 className="text-light mb-4 text-lg">{t('footer.links')}</h5>
            <Link to="/about-us" className="block mb-2 ml-3 lg:ml-0 text-gray-300 hover:text-red hover:underline transition duration-200 flex justify-center lg:justify-start items-center">
              <MdOutlineArrowRight className="h-6 w-6 -mx-1" />
              {t('header.aboutUs')}
            </Link>
            <Link to="/contact-us" className="block mb-2 text-gray-300 hover:text-red hover:underline transition duration-200 flex justify-center lg:justify-start items-center">
              <MdOutlineArrowRight className="h-6 w-6 -mx-1" />
              {t('header.contactUs')}
            </Link>
            <Link to="/our-branches" className="block mb-2 ml-3 lg:ml-0 text-gray-300 hover:text-red hover:underline transition duration-200 flex justify-center lg:justify-start items-center">
              <MdOutlineArrowRight className="h-6 w-6 -mx-1" />
              {t('aboutUsMenu.Our Brannches')}
            </Link>
            <Link to="/terms" className="block mb-2 text-gray-300 hover:text-red hover:underline transition duration-200 flex justify-center lg:justify-start items-center">
              <MdOutlineArrowRight className="h-6 w-6 -mx-1" />
              {t('footer.terms')}
            </Link>
            <Link to="/donate-us" className="block mb-2 text-gray-300 hover:text-red hover:underline transition duration-200 flex justify-center lg:justify-start items-center">
              <MdOutlineArrowRight className="h-6 w-6 -mx-1" />
              {t('footer.support')}
            </Link>
          </div>
          <div className="text-center lg:text-left">
            <h5 className="text-light mb-4 text-lg">{t('footer.newsletter')}</h5>
            <p className="text-gray-300">{t('footer.newstext')}</p>
            <div className="relative w-full mt-2">
              <input type="text" className="form-control p-1 bg-gray-500 rounded-lg w-full py-3" placeholder="Your email" />
              <button type="button" className="bg-red h-full absolute -top-2 px-4 rounded-r-lg hover:bg-[#ff8f1f] right-0 mt-2">Signup</button>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center text-gray-400 py-3">
        <div className="grid grid-cols-1 text-sm">
          <p>
            &copy;
            {currentYear}
            {' '}
            <Link to="/" className="text-gray-300">Jaya Mahakal Youth Group</Link>
            , All Rights Reserved
          </p>
          <p>
            Designed & Developed By
            <Link
              to="https://binodbhusal.onrender.com/"
              target="_blank"
              rel="noreferrer"
              className="text-gray-300 hover:text-blue transition duration-200"
            >
              {' '}
              {' '}
              Binod Bhusal
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
