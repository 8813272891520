const getStyles = (lng) => {
  switch (lng) {
    case 'en':
      return { fontSize: '15px' };
    case 'np':
      return { fontSize: '18px' };
      // Add more languages and styles as needed
    default:
      return { fontSize: '16px' };
  }
};
export default getStyles;
