import React, { useEffect } from 'react';

const FacebookPagePlugin = () => {
  useEffect(() => {
    // Function to load the Facebook SDK script
    const loadFacebookSDK = () => {
      // Check if the SDK is already loaded
      if (window.FB) {
        window.FB.XFBML.parse();
        return;
      }

      // eslint-disable-next-line func-names
      window.fbAsyncInit = function () {
        window.FB.init({
          xfbml: true,
          version: 'v20.0',
        });
      };

      // Create script element and append to the document
      // eslint-disable-next-line func-names
      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        const js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_GB/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    };

    loadFacebookSDK();
  }, []);

  return (
    <div className="mt-4 border border-rose-400 rounded-lg p-1 w-[308px] mx-auto">
      <div id="fb-root" />
      <div
        className="fb-page fb-video-custom"
        data-href="https://www.facebook.com/Jayamahakalyouthgroupnepal"
        data-tabs="timeline,live"
        data-width="300"
        data-height="300"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote cite="https://www.facebook.com/Jayamahakalyouthgroupnepal" className="fb-xfbml-parse-ignore">
          <a href="https://www.facebook.com/Jayamahakalyouthgroupnepal">जय महांकाल युवा समुह , काठमाडौं , नेपाल ।</a>
        </blockquote>
      </div>
    </div>
  );
};

export default FacebookPagePlugin;
