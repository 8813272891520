import { useTranslation } from 'react-i18next';
import BankDetails from '../othercomponents/bank-details';

const Success = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:px-16 px-6 pt-8 lg:pt-0 mb-4  mt-24   text-center h-auto flex justify-center flex-col">

      <h1 className=" bg-deep-green  text-deep-blue p-2 lg:w-2/3 mx-auto rounded-t text-2xl">
        🙏
        {t('formsuccess.heading')}
        🙏
      </h1>
      <div className="bg-gray-200 rounded-b-lg lg:w-2/3 mx-auto p-4">
        <span className="text-4xl mt-4">✅</span>

        <p className="text-gray-800 text-lg mb-6">
          {t('formsuccess.text')}
        </p>
        <BankDetails />
      </div>
    </div>
  );
};
export default Success;
