import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';

// Sample data for recent activities
const activities = [
  {
    id: 1,
    title: 'Pashupati Kotihome Volunteering',
    location: 'Kathmandu',
    date: 'July 25, 2024',
    image: '../assets/anuridavul.jpg',
    link: 'https://www.facebook.com/photo/?fbid=553856736966163&set=pcb.553857383632765',
  },
  {
    id: 2,
    title: 'Free Food Distribution',
    date: 'July 18, 2024',
    location: 'Kakadvitta',
    image: 'https://i.imgur.com/YHClWew.jpeg',
    link: 'https://www.facebook.com/photo/?fbid=549734557378381&set=pcb.549734577378379',
  },
  {
    id: 3,
    title: 'Comprehensive Cleaning Program',
    date: 'July 13, 2024',
    location: 'Pahsupati-Kathmandu',
    image: '../assets/vul2.jpg',
    link: 'https://www.facebook.com/photo?fbid=546795211005649&set=pcb.546795654338938',
  },
  {
    id: 4,
    title: 'Feeding the Hungry Program',
    date: 'June 16, 2024',
    location: 'Damak-Jhapa',
    image: '../assets/vul3.jpg',
    link: 'https://www.facebook.com/photo/?fbid=530172856001218&set=pcb.530173016001202',
  },
  {
    id: 5,
    title: 'Hanuman Chalisa Chanting',
    date: 'June 11, 2024',
    location: 'Mechinagar-Jhapa',
    image: '../assets/vul4.jpg',
    link: 'https://www.facebook.com/photo/?fbid=527509609600876&set=pcb.527510159600821',
  },
  {
    id: 5,
    title: 'Tree Planting',
    date: 'August 10, 2024',
    location: 'Bishnumati-Kathmandu',
    image: '../assets/treep.jpg',
    link: 'https://www.facebook.com/photo.php?fbid=563806482637855&set=pb.100070255234282.-2207520000&type=3',
  },
  // Add more activities as needed
];

const RecentActivities = () => {
  const { t } = useTranslation();
  return (
    <section id="recent-activities" className="py-16 bg-[#ff9933] rounded-lg">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8 text-gray-800 section-heading">{t('activities.recentActivities')}</h2>
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={30}
          navigation
          pagination={{ clickable: true }}
          loop
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {activities.map((activity) => (
            <SwiperSlide key={activity.id}>
              <div className="bg-gray-200 p-6 rounded-lg shadow text-left">
                <img
                  src={activity.image}
                  alt={activity.title}
                  className="w-full h-48 object-cover mb-4 rounded"
                />
                <h3 className="text-2xl min-w-[250px]font-bold mb-2">{t(`activities.${activity.title}`)}</h3>

                <p className="text-gray-600 mb-4">
                  {activity.date}
                  {' '}
                  ,
                  {' '}
                  {t(`activitiesLoaction.${activity.location}`)}
                </p>
                <a
                  href={activity.link}
                  className="inline-block bg-red text-white px-4 py-2 rounded hover:bg-[#ff8833]"
                >
                  {t('activities.btnseemoreactivity')}
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};
export default RecentActivities;
