import React from 'react';
import { Button } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Sample data for YouTube videos
const videos = [
  {
    id: 1,
    title: 'Prithvi Narayan Shah Birth Anniversary Celebration',
    description: 'Jaya Mahakal Youth Group is celebrating the birth anniversary of the great King Prithvi Narayan Shah',
    videoId: 'uSSIiZEofF8',
  },
  {
    id: 2,
    title: 'Free Food in Pashupatinath Temple',
    description: 'Feeding the hungry and quenching the thursty movement in Pashupatinath temple.',
    videoId: 'b9ZUAgJwPO4',
  },
  {
    id: 3,
    title: 'Empowering legless basketball',
    description: 'Jai Mahankal is empowering legless basketball players to achieve their dreams.',
    videoId: 'ML5xGMxrGQg',
  },
  {
    id: 4,
    title: 'Empowering legless basketball',
    description: 'Jai Mahankal is empowering legless basketball players to achieve their dreams.',
    videoId: 'J5fX3zC03pY',
  },
  {
    id: 5,
    title: '',
    description: '',
    videoId: 'ED0saIAOr4M',
  },
  {
    id: 6,
    title: '',
    description: '',
    videoId: 'QRkDdOOMklw',
  },
  // Add more videos as needed
];

const VideoLibrary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <section id="youtube-videos" className="py-20 bg-black lg:mt-32">
      <div className="lg:container lg:mx-auto text-center lg:border lg:border-gray-800 p-4 rounded-lg">
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-gray-700 hover:bg-gray-800 text-gray-200 px-8 py-1 rounded-lg mb-1"
            onClick={() => navigate('/media')}
          >
            Go Back
          </button>
        </div>
        <h2 className="text-4xl font-bold mb-8 text-gray-200 section-heading">{t('videos.videoTitle')}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {videos.map((video) => (
            <div className="relative px-2" key={video.id}>
              <iframe
                className="w-full h-64 border border-gray-700 rounded-lg"
                src={`https://www.youtube.com/embed/${video.videoId}`}
                title={video.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <div className="absolute inset-0 flex items-center justify-start p-6 pointer-events-none">
                <div className="bg-gradient-to-r from-black via-transparent to-transparent opacity-65 absolute inset-0 pointer-events-none" />
                <div className="text-left relative z-10 pointer-events-none" />
              </div>
            </div>
          ))}
        </div>
        <a href="https://youtube.com/@jayamahakalofficial6313?si=sCJLH_9tpb2GKnch">
          <Button className="mt-4 hover:bg-deep-green text-gray-100 bg-red py-2.5 px-16 rounded-lg text-lg">
            {t('videos.btnvideo')}
            {' '}
            &#8594;
          </Button>
        </a>
      </div>
    </section>
  );
};

export default VideoLibrary;
