import { Button } from '@headlessui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import MoreUpcomingActivities from './more-upcoming-activities';

// Sample data for upcoming activities
const activities = [
  {
    id: 1,
    title: 'Human awareness campaign',
    date: '2024',
    time: '10:00 AM - 5:00 PM',
    location: 'Every Local body, Nepal',
    contact: '01-5910176, 9841683737',
  },
  {
    id: 2,
    title: 'Public awareness program on climate change policies.',
    date: '2024',
    time: '9:00 PM - 5:00 PM',
    location: 'All over the country, Nepal',
    contact: '01-5910176, 9818088191',
  },
  {
    id: 3,
    title: 'Awakening of humanity in universities',
    date: '2024',
    time: '9:00 AM - 5:00 PM',
    location: '17 Universities, Nepal',
    contact: '01-5910176, 9818088191',
  },
  // Add more activities as needed
];

const UpcomingActivities = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isActivitiesPage = location.pathname === '/all-activities';
  return (
    <section id="upcoming-activities" className="py-12 bg-gray-100 rounded-lg lg:mt-32">
      <div className="container mx-auto">
        <h2 className="section-heading text-4xl font-bold mb-8 text-center">{t('upcomingActivities.upComingActivity')}</h2>
        <ul className="px-4  lg:w-4/5 lg:mx-auto">
          {activities.map((activity) => (
            <li key={activity.id} className=" rounded-lg items-center bg-white shadow-md mb-2 flex justify-between  rounded-lg overflow-hidden">
              <div className="w-1/2   bg-deep-green text-white p-4 ">
                <p>{activity.date}</p>
                <p>
                  {' '}
                  {activity.time}
                </p>

              </div>
              <div className="w-2/3 p-4 bg-white flex flex-col  justify-end bg-blue">
                <p className="font-bold">{t(`upcomingActivities.${activity.title}`)}</p>
                <p>
                  {t(`upcomingActivities.${activity.location}`)}
                  ,
                  {' '}
                  {activity.contact}
                </p>
              </div>
            </li>
          ))}
          {isActivitiesPage && <MoreUpcomingActivities /> }
          <Link
            to="/all-activities"
            className="flex items-center justify-center"
          >
            {!isActivitiesPage && (
              <Button className="mt-4 hover:bg-[#ff8833] text-gray-100 bg-red py-2.5 px-12 rounded-lg   text-lg">
                {t('upcomingActivities.btnseeactivity')}
                {' '}
                &#8594;
              </Button>
            )}

          </Link>
        </ul>

      </div>
    </section>
  );
};
export default UpcomingActivities;
