import { BsInfoSquareFill } from 'react-icons/bs';
import { FaPeopleGroup } from 'react-icons/fa6';
import { RiHomeOfficeFill } from 'react-icons/ri';

export const features = [
  {
    id: 1,
    name: 'Breif Introduction',
    description: 'developing humanity, philanthropy, and social spirit in our community',
    href: '/about-us',
    icon: BsInfoSquareFill,
  },
  {
    id: 2,
    name: 'Our Team',
    description: 'ensuring everyone has access to food, clothing, and shelter',
    href: '/our-team',
    icon: FaPeopleGroup,
  },
  {
    id: 3,
    name: 'Our Brannches',
    description: 'work tirelessly to provide food, clothing, and shelter to those in need',
    href: '/our-branches',
    icon: RiHomeOfficeFill,
  },
];

export const callToActions = [
  {
    name: 'See Demo',
    href: '#',
    icon: '',
  },
  {
    name: 'Contact Support',
    href: '#',
    icon: '',
  },
];
