/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdLocalPhone, MdOutlineEmail } from 'react-icons/md';
import SocialMediaIcons from '../othercomponents/social-media';

const Contact = () => {
  const { t } = useTranslation();
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();
  const handleSubmit = async (e) => {
    const isValid = await trigger();
    if (!isValid) {
      e.preventDefault();
    }
  };
  return (
    <div className="lg:px-16 px-6 py-16  mt-12 pt-16 lg:h-screen h-auto">

      {/* headings */}

      <div
        className="w-full"
        data-aos="fade-down"
        data-aos-delay="400"
        data-aos-duration="1000"
      >
        <div>
          <p className=" font-semibold lg:text-xl text-center mb-5">
            <span className="text-gray-200 capitalize section-heading">{t('header.contactustext')}</span>
          </p>
        </div>
      </div>
      <div
        className="lg:w-3/5 mx-auto"
      >
        <div
          className="basis-1/2 mt-10 md:mt-0"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-offset="200"
        >
          <form
            target="_blank"
            onSubmit={handleSubmit}
            action="https://formspree.io/f/mjkbwjjj"
            method="POST"
          >
            <input
              className="w-full bg-gray-300 font-semibold placeholder-opaque-black p-3 rounded-xl outline-0"
              type="text"
              placeholder="Full Name"
              {...register('name', {
                required: true,
                maxlength: 50,
              })}
            />
            {errors.name && (
            <p className="text-red mt-1">
              {errors.name.type === 'required' && 'Please Enter Full Name *'}
              {errors.name.type === 'maxLength' && 'The maxlength is 50 char *'}

            </p>
            )}
            <input
              className="w-full bg-gray-300 font-semibold placeholder-opaque-black p-3 mt-5 rounded-xl outline-0"
              type="text"
              placeholder="EMAIL"
              {...register('email', {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,

              })}
            />
            {errors.email && (
            <p className="text-red mt-1">
              {errors.email.type === 'required' && 'Please Enter Email *'}
              {errors.email.type === 'pattern' && 'Please enter valid email address *'}

            </p>
            )}
            <textarea
              className="w-full bg-gray-300 font-semibold placeholder-opaque-black p-3 mt-5 rounded-xl outline-0"
              type="text"
              placeholder="MESSAGE"
              rows="4"
              cols="50"
              {...register('message', {
                required: true,
                maxLength: 1000,

              })}
            />
            {errors.message && (
            <p className="text-red mt-1">
              {errors.message.type === 'required' && 'Please Enter Your Message *'}
              {errors.message.type === 'maxLength' && 'The Message Max Length is 1000 *'}

            </p>
            )}
            <button
              type="submit"
              className="py-2 w-full lg:w-1/2 lg:mx-auto flex justify-center px-5 mt-2 rounded-xl bg-red text-gray-200 hover:bg-yellow hover:text-deep-blue transition duration-500"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <p className=" font-semibold lg:text-xl text-center mt-4  mb-4">
        <span className="text-gray-200 capitalize section-heading">{t('header.contactustext1')}</span>
      </p>
      <div className="bg-gray-300 w-full lg:w-3/5 mx-auto py-4 rounded-lg">

        <div className="flex justify-center text-gray-800 flex ">
          <div className="text-center flex flex-col mt-2">
            <p className="text-gray-800 flex text-xl  items-center">
              <MdLocalPhone className="text-xl mr-1" />
              {t('footer.phone')}
            </p>
            <p className="text-gray-800 flex text-xl items-center">
              <MdOutlineEmail className="text-xl mr-1" />
              {t('footer.email')}
            </p>
            <SocialMediaIcons />
          </div>

        </div>
      </div>
    </div>
  );
};
export default Contact;
